<template>
  <div class="forgotpassword">
    <div class="signup-body">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="content col-md-6 col-lg-5 p-0">
            <div class="header">
              <h3 class="text-center">Forgot your password?</h3>
            </div>
            <hr>
            <div class="form pt-4 text-center">
              <p class="mb-1">Enter your email address to reset your password. <br>
              </p>
              <p style="line-height: 1.2;" class="mt-2 mb-4"><small>You may need to check your spam folder or unblock <br>team@citronworks.com.</small></p>
              <el-alert v-if="success" class="mb-4 text-left" style="line-height: 1.4;"
                        :title=" 'If a CitronWorks account exists for your email, an email will be sent with further instructions.' "
                        @close="success = false"
                        type="success">
              </el-alert>
              <el-form
                :label-position="labelPosition"
                label-width="75px"
                :model="forgotPassword"
                :rules="rules"
                ref="forgotPassword"
              >
                <el-form-item label="Email"
                              :error="errorMsg"
                              prop="email">
                  <el-input v-model="forgotPassword.email"></el-input>
                </el-form-item>
                <error-alert :errors="errors" title="" effect="dark" hide-error-icon />
                <div class="text-center buttons pb-4">
                  <el-button type="success" @click="submit('forgotPassword')">Recover my account</el-button>
                </div>
              </el-form>
              <div class="text-center pb-3">
                <a href="mailto:team@citronworks.com" class="forgot-link p small"> I can’t recover my account. Contact support.</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex"
  import axios from "axios"
  import {LOCAL_BASE_URL, OAUTH} from "../vuex/utils/constant"
  import ErrorAlert from '~/components/ErrorAlert'

  export default {
    components: { ErrorAlert },

    data () {
      return {
        labelPosition: 'left',
        success: false,
        rules: {
          email: [
            { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] },
            { validator: (rule, val, callback) => {
                if(!val) {
                  callback(new Error('Please input email address'))
                } else {
                  this.error = false
                  callback()
                }

              }, trigger: ['change', 'blur']
            }
          ]
        },
        forgotPassword: {
          email: '',
        },
        errors: {},
      }
    },

    computed: {
      ...mapState(['passwordForgot'])
    },

    methods: {
      submit (forgotPassword) {
          this.$refs[forgotPassword].validate((valid, errors) => {
            this.errors = errors
            if (valid) {
              axios.post(LOCAL_BASE_URL + OAUTH + '/password/email', {
                email: this.forgotPassword.email
              },{
                headers: {
                  'Content-Type': 'application/json',
                }
              }).then(response => {
                if(response.data.success) {
                  this.success = true
                }
              }).catch(error => {
                this.errors = { email: error.response.data } 
              })
              // this.$store.dispatch('forgotPassword', this.forgotPassword.email)
            }
          })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .forgotpassword {
    p {
      font-size: 16px;
      line-height: 1.6;
    }
    .form label.el-form-item__label:after{
      content: "*";
      color: #f56c6c;
      margin-left: 4px;
    }
    .el-form-item__error {
      padding-top: 0;
    }
  }

  @media (max-width: 767px){
    .forgotpassword .signup-body {
      padding: 20px !important;
    }
  }
</style>
